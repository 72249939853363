import { TugOrderStatus, TugsRequested } from "apina-frontend";
import { controlValuesSignal, disableWhen } from "common";
import { FormControl } from "@angular/forms";
import { computed } from "@angular/core";

export function disableTugOrderStatusWhenNeeded(tugsRequestedControl: FormControl<TugsRequested | null>, tugOrderStatusControl: FormControl<TugOrderStatus | null>): void {
    const tugsRequestedSignal = controlValuesSignal(tugsRequestedControl);
    const disableOrderStatus = computed(() => {
        const tugsRequested = tugsRequestedSignal();
        return tugsRequested == null || tugsRequested === TugsRequested.NONE;
    });
    disableWhen(disableOrderStatus, tugOrderStatusControl);
}
