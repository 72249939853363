import { LatePilotBoardingTimeReason } from "apina-frontend";
import { labeledValue, LabeledValue } from './labeled-value';
import { Instant, isPilotageStatePastStart, PilotageState } from "common";

export const LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS: readonly LabeledValue<LatePilotBoardingTimeReason>[] = [
    labeledValue(LatePilotBoardingTimeReason.PILOT_UNAVAILABLE, "Luotsia ei saatavilla"),
    labeledValue(LatePilotBoardingTimeReason.TRANSPORT_UNAVAILABLE, "Luotsinkuljetusta ei saatavilla"),
    labeledValue(LatePilotBoardingTimeReason.WORK_OPTIMIZATION, "Työajan optimointi"),
    labeledValue(LatePilotBoardingTimeReason.TRANSPORT_OPTIMIZATION, "Kuljetusten optimointi"),
    labeledValue(LatePilotBoardingTimeReason.UNEXPECTED_FINNPILOT, "Finnpilotista johtuva ennakoimaton myöhästyminen"),
    labeledValue(LatePilotBoardingTimeReason.OTHER, "Muu syy"),
];

export function needsLatePilotBoardingTimeReason(
    oldEta: Instant | null,
    oldPilotBoardingTime: Instant | null,
    newEta: Instant | null,
    newPilotBoardingTime: Instant | null,
    state: PilotageState,
): boolean {
    if (isPilotageStatePastStart(state)) return false;

    const oldOk = oldEta == null || oldPilotBoardingTime == null || oldPilotBoardingTime.compareTo(oldEta) <= 0;
    const newOk = newEta == null || newPilotBoardingTime == null || newPilotBoardingTime.compareTo(newEta) <= 0;

    return oldOk && !newOk;
}
