import { Instant } from "@js-joda/core";
import { FindRouteDto, PilotageDetails } from "apina-frontend";

export function pilotageStartTimeFor(eta: Instant, pilotBoardingTime: Instant | null): Instant {
    if (pilotBoardingTime == null || eta?.isAfter(pilotBoardingTime))
        return eta;
    else
        return pilotBoardingTime;
}

export function describePilotage(pilotage: PilotageDetails, route: FindRouteDto | undefined): string {
    return route !== undefined
        ? `${(pilotage.vessel.name)}: ${route.startCode}-${route.endCode} (${pilotage.id})`
        : `${(pilotage.vessel.name)} (${pilotage.id})`;
}
