@if (showForm()) {
    <div class="flex flex-col gap-2">
        <mat-form-field>
            <mat-label>Toimenpiteet luotsin saamiseksi palveluehtojen vastaiseen tilaukseen</mat-label>
            <mat-select [formControl]="form.controls.selection">
                <mat-option [value]="ActionsToDeliverPilot.NOT_NEEDED">{{ NOT_NEEDED_TEXT }}</mat-option>
                <mat-option [value]="ActionsToDeliverPilot.CUSTOM">Muu, mikä?</mat-option>
            </mat-select>
        </mat-form-field>

        @if (showDetails()) {
            <app-textarea-field label="Kuvaus toimenpiteistä" [control]="form.controls.custom"/>
        }
    </div>
}
