<mat-form-field class="flex-auto w-0" subscriptSizing="fixed">
    <mat-label>Maksimisyväys</mat-label>
    <input matInput type="number" [formControl]="controls.draft" [min]="1" [required]="required" [max]="maxDraft">
    <span matTextSuffix>m</span>
    @if (effectiveDraftBounds(); as bounds) {
        <mat-hint align="end"><span class="whitespace-nowrap">{{ bounds.maxDraft | bounds:"m" }}</span></mat-hint>
    }
</mat-form-field>
<mat-form-field class="flex-auto w-0" subscriptSizing="fixed">
    <mat-label>Keulasyväys</mat-label>
    <input matInput type="number" [formControl]="controls.draftFore" [min]="1" [max]="maxDraft">
    <span matTextSuffix>m</span>
    @if (effectiveDraftBounds(); as bounds) {
        <mat-hint align="end"><span class="whitespace-nowrap">{{ bounds.iceDraftFore | bounds:"m" }}</span></mat-hint>
    }
</mat-form-field>
<mat-form-field class="flex-auto w-0" subscriptSizing="fixed">
    <mat-label>Peräsyväys</mat-label>
    <input matInput type="number" [formControl]="controls.draftAft" [min]="1" [max]="maxDraft">
    <span matTextSuffix>m</span>
    @if (effectiveDraftBounds(); as bounds) {
        <mat-hint align="end"><span class="whitespace-nowrap">{{ bounds.iceDraftAft | bounds:"m" }}</span></mat-hint>
    }
</mat-form-field>
<mat-form-field class="flex-auto w-0" subscriptSizing="fixed">
    <mat-label>Kulkukorkeus</mat-label>
    <input matInput type="number" [formControl]="controls.airDraft" [min]="1" [max]="maxAirDraft">
    <span matTextSuffix>m</span>
</mat-form-field>
