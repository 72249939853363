import { ChangeDetectionStrategy, Component, computed, input, Input, signal, Signal } from "@angular/core";
import { AbstractMatFormFieldControl, controlValuesSignal } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { of as observableOf } from "rxjs";
import { distinctUntilChanged, switchMap, takeUntil, tap } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { BerthId, BerthInfo, EndpointId, RouteEndpoint } from "apina-frontend";
import { MatSelectModule } from "@angular/material/select";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";

@Component({
    selector: "app-select-berth",
    templateUrl: "./select-berth.component.html",
    providers: [
        {provide: MatFormFieldControl, useExisting: SelectBerthComponent},
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatSelectModule,
        ReactiveFormsModule,
    ],
})
export class SelectBerthComponent extends AbstractMatFormFieldControl<BerthId> {

    private static nextId = 0;

    readonly control = new FormControl<BerthId | null>(null, {nonNullable: true});

    private readonly endpointId$ = signal<EndpointId | null>(null);
    readonly berths: Signal<readonly BerthInfo[]>;
    readonly selectedBerth: Signal<BerthInfo | null | undefined>;

    /** Ugly hack so that we don't send change-event back up when value is set from up */
    private isSettingValue = false;

    hint = input<string | null | undefined>(undefined);

    get value(): BerthId | null {
        return this.control.value;
    }

    set value(id: BerthId | null) {
        if (id !== this.value) {
            this.isSettingValue = true;
            this.control.reset(id);
            this.stateChanges.next();
            this.isSettingValue = false;
        }
    }

    @Input()
    set endpointId(id: EndpointId | null | undefined) {
        if (id !== this.endpointId$()) {
            this.endpointId$.set(id ?? null);
            this.stateChanges.next();
        }
    }

    constructor(routeEndpoint: RouteEndpoint) {
        super("app-select-berth", SelectBerthComponent.nextId++);

        this.berths = toSignal(toObservable(this.endpointId$).pipe(
                switchMap(id => (id == null) ? observableOf([]) : routeEndpoint.findBerths(id)),
                tap(berths => this.setDisabledState(berths.length === 0))),
            {initialValue: []});

        const berthId = controlValuesSignal(this.control);
        this.selectedBerth = computed(() => {
            const id = berthId();
            if (berthId != null)
                return this.berths()?.find(it => it.id === id);
            else
                return null;
        });
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled === this.control.disabled) return;

        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        this.control.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(v => {
            if (!this.isSettingValue) {
                fn(v);
            }
        });
    }
}
