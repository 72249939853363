import { ChangeDetectionStrategy, Component, computed, effect, Inject, Optional, signal, Signal } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BerthId, FindRouteDto, LatePilotBoardingTimeReason, PilotageDetails, PilotageEditDetails, PilotageEndpoint, PilotageNoticeEndpoint, PilotageScheduleSource, RouteEndpoint, RouteId, StandardNoticeDto, StandardNoticeId, TugOrderStatus, TugsRequested, UpdateEtaParameters } from "apina-frontend";
import { Observable } from "rxjs";
import { arrayOfNotNull, arrayOfNumbersInRange, controlValuesSignal, dateFormat, Duration, durationAsHoursAndMinutes, enableWhen, HelsinkiDatePipe, Instant, isPilotageStatePastStart, MAX_DRAFT_METERS, Minutes, MyMatDateTimePickerComponent, PilotageState } from "common";
import { LabeledValue } from "../../domain/labeled-value";
import { LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS, needsLatePilotBoardingTimeReason } from '../../domain/pilot-boarding-time';
import { ActivatedRoute } from "@angular/router";
import { SelectScheduleSourceComponent, SelectScheduleSourceType } from "../../common/select-schedule-source/select-schedule-source.component";
import { describePilotage, pilotageStartTimeFor } from "../../domain/pilotage";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { disableTugOrderStatusWhenNeeded } from "../../domain/tugs";
import { SelectTugsRequestedComponent } from "../../common/select-tugs-requested/select-tugs-requested.component";
import { createDraftControls, EditDraftFieldsComponent } from "../../common/edit-draft-fields/edit-draft-fields.component";
import { SelectRouteComponent } from "../../common/select-route/select-route.component";
import { SelectBerthComponent } from "../../common/select-berth/select-berth.component";
import { DurationPipe } from "../../common/pipes/duration.pipe";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TextFieldModule } from "@angular/cdk/text-field";
import { PilotDeliveryActionsComponent } from "../pilot-delivery-actions/pilot-delivery-actions.component";
import { MatRadioModule } from "@angular/material/radio";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { BerthFieldComponent } from "../../forms/berth-field/berth-field.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";
import { TextFieldComponent } from "../../forms/text-field/text-field.component";

@Component({
    templateUrl: './update-eta.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonDialogFormComponent,
        DurationPipe,
        EditDraftFieldsComponent,
        HelsinkiDatePipe,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MyMatDateTimePickerComponent,
        PilotDeliveryActionsComponent,
        ReactiveFormsModule,
        SelectBerthComponent,
        SelectRouteComponent,
        SelectScheduleSourceComponent,
        SelectTugsRequestedComponent,
        TextFieldModule,
        VerticalFormComponent,
        InputRowComponent,
        BerthFieldComponent,
        DatetimeFieldComponent,
        ScheduleSourceFieldComponent,
        TextareaFieldComponent,
        TextFieldComponent,
    ],
})
export class UpdateEtaComponent implements CommonDialogFormDelegate {

    readonly details: PilotageEditDetails;
    readonly pilotage: PilotageDetails;

    readonly form = new FormGroup({
        eta: new FormControl<Instant | null>(null, Validators.required),
        source: new FormControl<PilotageScheduleSource | null>(null, Validators.required),
        latePilotBoardingTimeReason: new FormControl<LatePilotBoardingTimeReason | null>(null, Validators.required),
        ...createDraftControls(),
        routeId: new FormControl<RouteId | null>(null, Validators.required),
        pilotBoardingTime: new FormControl<Instant | null>(null),
        startBerthId: new FormControl<BerthId | null>(null),
        endBerthId: new FormControl<BerthId | null>(null),
        standardNoticeId: new FormControl<StandardNoticeId | null>(null),
        notice: new FormControl<string | null>(null),
        billingNotice: new FormControl<string | null>(null),
        removeAnchorage: new FormControl<boolean | null>(null, Validators.required),
        pilot1AlarmTimeDifference: new FormControl<Minutes | null>(null),
        pilot2AlarmTimeDifference: new FormControl<Minutes | null>(null),
        tugsRequested: new FormControl<TugsRequested | null>(null),
        tugOrderStatus: new FormControl<TugOrderStatus | null>(null),
        tugNotice: new FormControl<string | null>(null),
    });

    readonly title: Signal<string>;
    readonly startTimeLabel: string;
    readonly showPilotBoardingTime: boolean;
    readonly selectableSourceTypes: SelectScheduleSourceType;
    readonly selectedRoute: Signal<FindRouteDto | undefined>;
    readonly routeHint: Signal<string>;
    readonly pilotageStartTime: Signal<Instant>;
    readonly selectableAlertDeltas: Signal<readonly AlertDelta[]>;
    readonly originalPilotEta = signal<Instant | null>(null);
    readonly standardNotices: Signal<readonly StandardNoticeDto[]>;
    readonly maxDraft = MAX_DRAFT_METERS;
    readonly TugOrderStatus = TugOrderStatus;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) params: UpdateEtaComponentParams | undefined,
        route: ActivatedRoute,
        private readonly pilotageEndpoint: PilotageEndpoint,
        pilotageNoticeEndpoint: PilotageNoticeEndpoint,
        routeEndpoint: RouteEndpoint,
    ) {
        this.details = params != null ? params.details : route.snapshot.data['details'];
        this.pilotage = this.details.pilotage;
        const pilotage = this.pilotage;

        this.standardNotices = toSignal(pilotageNoticeEndpoint.getPilotageStandardNotices(pilotage.standardNotice?.id ?? null), {initialValue: []});

        disableTugOrderStatusWhenNeeded(this.form.controls.tugsRequested, this.form.controls.tugOrderStatus);

        const routes = toSignal(routeEndpoint.getRoutes());
        const selectedRouteId = controlValuesSignal(this.form.controls.routeId);

        this.routeHint = computed(() => routeHint(selectedRouteId(), pilotage));

        this.selectedRoute = computed(() => {
            const routeId = selectedRouteId();
            return routes()?.find(it => it.id === routeId);
        });

        this.form.reset({
            eta: pilotage.startTime,
            pilotBoardingTime: pilotage.pilotBoardingTime ?? pilotage.startTime,
            source: pilotage.scheduleSource,
            routeId: pilotage.route.id,
            startBerthId: pilotage.route.start?.berth?.id ?? null,
            endBerthId: pilotage.route.end?.berth?.id ?? null,
            draft: pilotage.drafts.max,
            draftFore: pilotage.drafts.fore,
            draftAft: pilotage.drafts.aft,
            airDraft: pilotage.drafts.air,
            standardNoticeId: pilotage.standardNotice?.id ?? null,
            notice: pilotage.notice,
            billingNotice: pilotage.billingNotice,
            pilot1AlarmTimeDifference: pilotage.pilot1AlarmTimeDifference,
            pilot2AlarmTimeDifference: pilotage.pilot2AlarmTimeDifference,
            tugsRequested: pilotage.tugsRequested,
            tugOrderStatus: pilotage.tugOrderStatus,
            tugNotice: pilotage.tugNotice,
        });

        // Reset berths when user changes route
        let previousStart = pilotage.route.start.id;
        let previousEnd = pilotage.route.end.id;
        effect(() => {
            const route = this.selectedRoute();
            if (route !== undefined) {
                if (route.startId !== previousStart) {
                    this.form.controls.startBerthId.reset(null);
                    previousStart = route.startId;
                }
                if (route.endId !== previousEnd) {
                    this.form.controls.endBerthId.reset(null);
                    previousEnd = route.endId;
                }
            }
        });

        this.title = computed(() => describePilotage(pilotage, this.selectedRoute()));

        const hasAnchorage = pilotage.estimatedAnchorageArrivalTime != null;

        const confirmAnchorageRemoval = computed(() =>
            hasAnchorage && this.selectedRoute() !== undefined && pilotage.route.start.id !== this.selectedRoute()?.startId);

        const etaSignal = controlValuesSignal(this.form.controls.eta);
        const pilotBoardingTimeSignal = controlValuesSignal(this.form.controls.pilotBoardingTime);
        const needsLatePilotBoardingTimeReasonSignal = computed(() => needsLatePilotBoardingTimeReason(pilotage.eta, pilotage.pilotBoardingTime, etaSignal(), pilotBoardingTimeSignal(), pilotage.state));
        this.pilotageStartTime = computed(() => pilotageStartTimeFor(etaSignal() ?? pilotage.eta, pilotBoardingTimeSignal()));
        this.selectableAlertDeltas = computed(() => createSelectableAlertDeltas(this.pilotageStartTime(), arrayOfNotNull(pilotage.pilot1AlarmTimeDifference, pilotage.pilot2AlarmTimeDifference)));

        this.form.controls.eta.valueChanges.subscribe(v => {
            if (this.form.controls.pilotBoardingTime.pristine) {
                this.originalPilotEta.set(this.form.controls.pilotBoardingTime.value);
                this.form.controls.pilotBoardingTime.reset(v);
            }

            if (this.form.controls.source.pristine && pilotage.state !== PilotageState.ORDER) {
                this.form.controls.source.reset(null);
            }
        });

        this.showPilotBoardingTime = pilotage.state === PilotageState.ORDER && pilotage.pilot != null;
        this.selectableSourceTypes = (pilotage.state === PilotageState.NOTICE || pilotage.state === PilotageState.ORDER) ? SelectScheduleSourceType.EDIT_NOTICE_OR_ORDER : SelectScheduleSourceType.ALL;
        this.startTimeLabel = isPilotageStatePastStart(pilotage.state) ? "Alkuaika" : "ETA/ETD";

        enableWhen(confirmAnchorageRemoval, [this.form.controls.removeAnchorage]);
        enableWhen(needsLatePilotBoardingTimeReasonSignal, [this.form.controls.latePilotBoardingTimeReason]);

        function createSelectableAlertDeltas(startTime: Instant, existing: Minutes[]): AlertDelta[] {
            const minutes = arrayOfNumbersInRange(0, 20).map(i => i * 15);
            for (const t of existing)
                if (!minutes.includes(t))
                    minutes.push(t);

            minutes.sort((a, b) => a - b);
            return minutes.map(m => new AlertDelta(m, startTime));
        }
    }

    get latePilotBoardingTimeReasonSelections(): readonly LabeledValue<LatePilotBoardingTimeReason>[] {
        return LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS;
    }

    doSave(): Observable<unknown> {
        const updateParams = this.form.value as UpdateEtaParameters;
        if (this.form.controls.eta.pristine)
            updateParams.eta = null;
        return this.pilotageEndpoint.updateEta(this.details.pilotage.id, updateParams);
    }

    resetPilotEtaToOriginal(event: MouseEvent): void {
        event.preventDefault();

        this.form.controls.pilotBoardingTime.reset(this.originalPilotEta());
    }

    formatAlarmTimeDifference(minutes: Minutes | null): string {
        return durationAsHoursAndMinutes(Duration.ofMinutes(minutes ?? 0));
    }

    alarmTime(minutes: Minutes | null): Instant | null {
        return (minutes != null) ? this.pilotageStartTime().minus(Duration.ofMinutes(minutes)) : null;
    }
}

function routeHint(routeId: RouteId | null, pilotage: PilotageDetails): string {
    const lines: string[] = [];

    if (pilotage.estimatedAnchorageArrivalTime != null)
        lines.push(`Ulkoankkuriin: ${dateFormat(pilotage.estimatedAnchorageArrivalTime, "dd.MM. HH.mm")}`);

    if (routeId !== pilotage.route.id)
        lines.push(`Alkuperäinen: ${pilotage.route.name}`);

    return lines.join("\n");
}

class AlertDelta {

    readonly duration: Duration;
    readonly time: Instant;

    constructor(readonly minutes: number, pilotageStartTime: Instant) {
        this.duration = Duration.ofMinutes(minutes);
        this.time = pilotageStartTime.minus(this.duration);
    }
}

export interface UpdateEtaComponentParams {
    details: PilotageEditDetails,
}
