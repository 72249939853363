import { PilotageDirection, TosConflictReason } from "apina-frontend";
import { Duration } from "@js-joda/core";
import { isDirectionInbound } from "./pilotage-direction";
import { TOS_INBOUND_NOTICE1_VALID_START, TOS_OUTBOUND_NOTICE1_VALID_START } from "common";

export function firstNoticeTimeForDirection(direction: PilotageDirection): Duration {
    return isDirectionInbound(direction) ? TOS_INBOUND_NOTICE1_VALID_START : TOS_OUTBOUND_NOTICE1_VALID_START;
}

export const tosConflictReasonLabels: Record<TosConflictReason, string> = {
    CHANGE_NOT_WITHIN_MARGIN: "Muutosta ei ole annettu sallitussa aikaikkunassa",
    ETA_CHANGED_WAY_TOO_MUCH: "ETA-aikaa siirretty enemmän kuin sallittua",
};
