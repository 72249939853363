import { Pipe, PipeTransform } from "@angular/core";
import { Duration, durationAsHoursAndMinutes } from "common";

@Pipe({
    name: 'duration',
    standalone: true,
    pure: true,
})
export class DurationPipe implements PipeTransform {

    transform(value: Duration | null | undefined, zeroAsEmpty = false): string {
        if (value == null || zeroAsEmpty && value.isZero())
            return "";
        else
            return durationAsHoursAndMinutes(value);
    }
}
