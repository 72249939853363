import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { PilotageScheduleSource } from "apina-frontend";
import { SelectScheduleSourceComponent, SelectScheduleSourceType } from "../../common/select-schedule-source/select-schedule-source.component";

@Component({
    selector: 'app-schedule-source-field',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        SelectScheduleSourceComponent,
    ],
    templateUrl: './schedule-source-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "class": "block",
    }
})
export class ScheduleSourceFieldComponent {

    label = input.required<string>();
    control = input.required<FormControl<PilotageScheduleSource | null>>();
    type = input<SelectScheduleSourceType>(SelectScheduleSourceType.ALL);
    hint = input<string | null>(null);
}
