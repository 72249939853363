import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { of as observableOf } from "rxjs";
import { NewPilotageTemplate, PilotageEndpoint, PortCallPilotageDirection } from "apina-frontend";
import { parsePilotageId, parsePortnetPortCallId } from "../../domain/id-parsing";

const PARAM_FROM = "from";
const PARAM_MODE = "mode";
const PARAM_DIRECTION = "direction";

export const newPilotageFromResolver: ResolveFn<NewPilotageTemplate | null> = route => {
    const pilotageEndpoint = inject(PilotageEndpoint);

    const queryParams = route.queryParamMap;

    const mode = queryParams.get(PARAM_MODE) as (NewPilotageSourceMode | null);
    const from = queryParams.get(PARAM_FROM);

    if (mode != null && from != null) {
        switch (mode) {
            case NewPilotageSourceMode.REVERSE:
                return pilotageEndpoint.getTemplateForReversePilotage(parsePilotageId(from));
            case NewPilotageSourceMode.PORTCALL: {
                const direction = queryParams.get(PARAM_DIRECTION) as PortCallPilotageDirection;
                return pilotageEndpoint.getTemplateForPortCall(parsePortnetPortCallId(from), direction);
            }
            case NewPilotageSourceMode.CANCELLED:
                return pilotageEndpoint.getTemplateForNewPilotageFromCancelled(parsePilotageId(from));
        }
    } else
        return observableOf(null);
};

export enum NewPilotageSourceMode {
    CANCELLED = "cancelled",
    REVERSE = "reverse",
    PORTCALL = "portcall"
}
